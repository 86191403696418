@font-face {
	font-family: "Montserrat-Black";
	src: url("../../assets/fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Bold";
	src: url("../../assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-SemiBold";
	src: url("../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Regular";
	src: url("../../assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Medium";
	src: url("../../assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Light";
	src: url("../../assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}


@font-face {
	font-family: "Protest";
	src: url("../../assets/fonts/protest/ProtestRiot-Regular.ttf") format("truetype");
}
