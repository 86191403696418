.tt__language__selector{
    width: 50px;
    height: auto;
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    font-family: "Protest";
    z-index: 999; 

    &__item{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $yellow-color;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        &:hover{
            background: $orange-color;
            color: $white-color;
        }        
    }
}
