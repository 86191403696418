.totters__button{
            min-width: 100px;
            width: auto;
            height: 40px;
            padding: 5px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $black-color;
            background-color: $yellow-color;
            font-family: "Protest";
            font-size: 20px;
            line-height: 30px;
            border: 0px;
            border-radius: 10px;
            cursor: pointer;

            &:hover{
                background-color: $orange-color;
                color: $white-color;
                scale: 1.1;
            }
            
            &--big_option{
                width: 600px;
                height: auto;
                display: block;
                margin-bottom: 20px;
                padding: 10px 0px 10px 0px;

                &:hover{
                    scale: 1.1;
                }
            }

            &--action{
                width: 200px;
                height: auto;
                display: block;
                padding: 10px 0px 10px 0px; 
                margin: 0 auto;
                margin-bottom: 20px;
                text-align: center;
            }

            &--pink{
                background-color: $pink-color;
                color: $white-color;
            }

            &--orange{
                background-color: $orange-color;
                color: $white-color;
            }

            &--yellow{
                background-color: $yellow-color;
                color: $black-color;
            }

            &--blue{
                background-color: $blue-color;
                color: $white-color;
            }

            &--green{
                background-color: $green-color;
                color: $white-color;
            }

            &--active{
                background-color: $orange-color;
                color: $white-color;
            }
}

@media screen and (max-width: 1170px){
    .totters__button{
        &--big_option{
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
        }
    }
}

@media screen and (max-width: 768px){
    .totters__button{
        font-size: 14px;
        line-height: 24px;
    }
}