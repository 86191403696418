.totter__modal {
	&__content {
		width: 100%;
		height: auto;
		margin: 0 auto;
	}

}

.ant-modal-wrap{
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: $white-color !important;
}

.ant-modal .ant-modal-content {
    color: $white-color !important;
    background-color: $blue-color;
}

.ant-modal .ant-modal-content .ant-modal-title {
    background-color:$black-color;
    color: $white-color;
}

.ant-modal .ant-modal-close {
    color: $white-color;
}

.ant-modal .ant-modal-close:hover {
    color: $blue-color;
}

@media screen and (max-width: 768px){

}