$blue-color: #4d7fb2;
$strong-blue-color: #003551;
$white-color: #ffffff;
$black-color: #000000;
$strong-gray-color:#1E1E1E;
$gray-color: #F7F7F7;
$yellow-color: hsl(42.78deg 100% 56.27%);
$orange-color: rgb(220, 78, 40);
$pink-color: rgb(204, 126, 145);
$green-color: #36a566;

$theme: "Montserrat-Regular";

// Paleta de colores
$colors: (
"blue-color": $blue-color,
"strong-blue-color": $strong-blue-color,
"white-color": $white-color,
"black-color": $black-color, 
"gray-color": $gray-color, 
"yellow-color": $yellow-color, 
"orange-color": $orange-color, 
"pink-color": $pink-color, 
"green-color": $green-color, 
"strong-gray-color": $strong-gray-color
);
