.totters__contact__form{
    width: 100%;
    height: auto;
    display: flex;
    padding: 40px 0px 40px 0px;
    justify-content: center;
    align-items: center;

    

    &__characters{
        width: 200px;
        height: auto;
        margin: 0 auto;
        margin-bottom: 20px;
    }

    &__block{
        width: 100%;
        height: auto;
        padding-left: 10px;
    }

    &__thanks{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 0px;
        background-color: $white-color;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        position: relative;

        .totters__button{
            margin: 0 auto;
            margin-bottom: 30px;
        }

        h3{
            font-size: 20px !important;
            line-height: 30px !important;
            margin-bottom: 0px !important;
            font-weight: 400 !important;
            font-family: "Protest";
            text-align: center;
            color: $blue-color !important;
            border-radius: 10px;
            padding: 5px;
            box-sizing: border-box;
            padding-bottom: 30px;

        }

        &__characters{
            width: 140px;
            height: auto;
            margin: 0 auto;
        }
    }


    h3{
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 40px;
        font-family: "Protest";
        text-align: center;
        color: $yellow-color;
    }

    p{
        font-size: 14px;
        line-height: 20px;
    }

    .contact__data{
        width: 100%;
        height: auto;
        margin-bottom: 30px;

        &__container{
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
        }
        &__icon{
            width: 30px;
            height: auto;
            font-size: 30px;
            margin-right: 10px;
        }

        &__text{
            width: 80%;
            strong{
                display: block;
                margin-bottom: 10px;
                font-weight: 600;
            }  
        }
    }


    .contact__form__base{
        width: 100%;
        height: auto;
        border-radius: 10px;
        padding-top: 30px;
        padding-bottom: 30px;

        &__container{
            width:90%;
            height: auto;
            margin: 0 auto;
        }
    }

    .contact__form__fields{
        width: 100%;
        display: block;
        input{
            width: 100%;
            height: 30px;
            background: transparent;
            border: 0px;
            color: $white-color;
            border-bottom: 1px solid $white-color;
            padding-bottom: 10px;
            margin-bottom: 30px;
            font-family: "Protest";
            font-size: 16px;
        }

        ::placeholder {
            color: $white-color;
            opacity: 1; /* Firefox */
        }

        .labelError{
            color: $white-color;
            background: red;
            margin-bottom: 10px;
            font-family: "Protest";
            position: relative;
            padding:5px;
            border-radius: 5px;
            top: -20px;
        }

        .labelError:empty {
            padding: 0;
            background: none; 
        }
        input[type='submit'] {
            width: 300px;
            height: 60px;
            margin: 0 auto;
            display: block;
            background-color: $white-color;
            border: 1px solid $white-color;
            color: $blue-color;
            cursor: pointer;
            font-size: 20px;
            border-radius: 50px;
            padding-top: 10px;
            padding-bottom: 10px;
            font-family: "Protest";

            &:hover{
                border: 1px solid $yellow-color;
                background-color: $yellow-color;
                color: $orange-color;
            }
        }
    }


    &--service{
        .contact__form__base{
            background-color: $white-color;
        }

        .contact__form__base__logo{
            display: block;
            margin: 0 auto !important;
            margin-bottom: 30px !important;
        }

        .totters__contact__form__block{
            width: 50% !important;
            p{
                text-align: left !important;
            }

            h4{
                font-size: 18px;
                line-height: 28px;
                width: 90%;
                text-align: center;
            }
        }
        input{
            width: 100%;
            height: 30px;
            border: 0px;
            border-bottom: 1px solid #757575 !important;
        }

        ::placeholder {
            color: #757575 !important;
            opacity: 1; /* Firefox */
        }

        input[type='submit'] {
            border: 1px solid $strong-blue-color !important;
            color: $strong-blue-color !important;

            &:hover{
                background-color: $strong-blue-color;
                color: $white-color !important  ;
            }
        }
    }

}

@media screen and (max-width: 768px){
    .totters__contact__form{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0px;

        &__base{
            &__container{
                width: 100%;
            }
        }

        &__thanks{
            width: 80%;
            height: auto;
            margin: 0 auto;

            &__characters{
                width: 120px;
            }


            h3{
                font-size: 18px !important;
                line-height: 28px !important;
            }
        }

        h3 {
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 40px;
        }
    
        &__block{
            width: 100%;
            height: auto;
            padding-left: 10px;
        }
    }

    

}


@media screen and (max-width: 420px){
    .totters__contact__form{
        
    
        .contact__form__fields{
            input[type='submit'] {
                width: 100%;
                height: 50px;
                font-size: 18px;
            }
        }


        
    }

}
