.ant-steps{
    margin-bottom: 60px !important;
}

.ant-steps-item-icon{
    border-color: $yellow-color !important;
    background-color: $yellow-color !important;
    svg{
        fill: $black-color !important;
    }
}
.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: $yellow-color !important;
}


.ant-steps-item-title{
    font-family: "Protest";
}