.nv__home__page{
    width: 100%;    
    height: 100vh;
    h1{
        font-size: 60px;
        line-height: 70px;
        font-family: "Protest";
        text-align: center;
        color: $orange-color;  
    }

    h3{
        font-size: 30px;
        line-height: 40px;
        font-family: "Protest";
        text-align: center;
        margin-bottom: 30px;
    }

    h4{
        font-weight: 400;
    }

    p{
        font-size: 18px;
        line-height: 28px;
        text-align:center;
        margin-bottom: 60px;
    }

    .totters{
        width: 100%;
        height: auto;
        display: flex;
        overflow-y: auto;
        &__base{
            width: 80%;
            height: auto;
            text-align: center;
            margin: 0 auto;
            border-radius: 10px;
            margin-bottom: 60px;
            display: flow-root;

            &__question{
                display: inline-block;
                font-family: "Protest";
                font-size: 30px;
                line-height: 40px;
                text-align:center;
                margin-bottom: 70px;
                padding-bottom: 10px;
                &--base{
                    font-size: 35px;
                    line-height: 45px;
                    margin-bottom: 50px;
                    color: $blue-color;
                }
            }

            .question__line{
                &--yellow{
                    border-bottom: 3px solid $yellow-color;
                }

                &--pink{
                    border-bottom: 3px solid $pink-color;
                }

                &--blue{
                    border-bottom: 3px solid $blue-color;
                }

                &--orange{
                    border-bottom: 3px solid $orange-color;
                }

                &--green{
                    border-bottom: 3px solid $green-color;
                }
            }
            &__options{
                width: 100%;
                height: auto;
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                margin-bottom: 20px;
                
                &--small{
                    width: 400px;
                }
            }
        }

        &__image{
            width: 200px;
            height: auto;
            margin: 0 auto;
            margin-bottom: 20px;

            &--small{
                width: 140px;
            }
        }

        &__characters{
            width: 300px;
            height: auto;
            margin: 0 auto;
            margin-bottom: 20px;
        }

        &__patter{
            width: 30%;
            height: auto;
            min-height: 100vh;
            background: url("../../../assets/images/patter.jpg");
            background-size:contain;

        }

        &__patter__mobile{
            width: 100%;
            height: 200px;
            background: url("../../../assets/images/Patron.jpg");
            background-size:contain;
            display: none;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }


        &__form{
            width: 70%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow-y: auto;

            &__container{
                width: 80%;
                height: auto;
                margin: 0 auto;
            }


            &__question{
                font-family: "Protest";
                font-size: 30px;
                text-align:center;
                
                span{
                    border-radius: 10px;
                    padding: 10px;
                    background-color: $yellow-color;

                }
            }
        }
    }

}


@media screen and (max-width: 992px){
    .nv__home__page{
        
    p{
        font-size: 16px;
        line-height: 26px;
    }
        .totters {

            &__characters{
                width: 240px;
            }
            &__base{
                
                &__question{
                    font-size: 25px;
                    line-height: 35px;
                    &--base{
                        font-size: 25px;
                        line-height: 35px;
                        margin-bottom: 50px;
                    }
                }
            }
            
        }
    }
}

@media screen and (max-width: 768px){
    .nv__home__page{
        .totters {
            &__patter{
                width: 30%;
            }
            &__form{
                width: 90%;
            }
        }
    }
}

@media screen and (max-width: 680px){
    .nv__home__page{

        .totters {
            &__base{
                width: 100%;
                &__options{
                    &--small{
                        width: 100%;
                    }
                }
            }

            &__form{
                &__container{
                    width: 90%;
                }
            }
            &__patter{
                width: 30%;
                display: none;
            }

            &__patter__mobile{
                display: block;
            }
            &__form{
                width: 100%;
                min-height: 80vh;
                height: auto;
            }
        }
    }
}

@media screen and (max-width: 480px){
    .nv__home__page{
       
        .totters {
            &__patter__mobile{
                background-size: cover;
                height: 98px;
                margin-bottom: 30px;
            }

            &__base{
                
                &__question{
                    font-size: 22px;
                    line-height: 32px;
                    &--base{
                        font-size: 22px;
                        line-height: 32px;
                        margin-bottom: 50px;
                    }
                }
            }
        }
    }

}